.text-area-wrapper {
  position: relative;
  textarea {
    width: 100%;
  }

  .code-edit-valid {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 10px;
    right: 10px;
    border-radius: 50px;
    background-color: red;

    &.true {
      background-color: green;
    }
  }
}
