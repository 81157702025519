@import "../../assets/stylesheets/variables.scss";

.story-value-engine {
  h3 {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: initial;
    text-transform: none;
    -webkit-transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    -moz-transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    border-bottom: 1px solid #979797;
    margin: 20px 0px;
    padding-bottom: 10px;
  }

  .story-value-engine-table {
    background-color: white;
    display: flex;

    div {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .details-container-title {
      font-family: "Source Sans Pro Webfont";
      font-size: 20px;
      font-weight: 600;
      color: #4a4a4a;
      width: 220px;
      padding-left: 30px;
      font-weight: 20px;
    }

    .details-container {
      min-width: 200px;
    }

    .section-title {
      font-family: "Roboto Slab Webfont";
      color: #1abcae;
      font-size: 20px;
      font-weight: 300;
      vertical-align: top;
    }

    .section-data {
      font-family: "Roboto Slab Webfont";
      font-size: 18px;
      padding: 10px 10px;
    }

    .data-not-available {
      font-family: "Source Sans Pro Webfont";
      font-size: 16px;
      font-weight: 300;
      font-style: italic;
      padding: 12px 10px;
    }
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .story-value-engine {
    .story-value-engine-table {
      flex-direction: column;
    }
  }
}
