.new-user-page {
  * {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
  }

  width: 100%;
  height: 100vh;
  background-color: cadetblue;
  padding-top: 300px;
  box-sizing: border-box;

  .login-logo-container {
    width: 110px;
  }

  form {
    width: 270px;
    margin: auto;
    color: white;

    input {
      width: 100%;
      border: none;
      border-radius: 3px;
      color: white;
      background-color: rgba(0, 0, 0, 0.2);
      height: 40px;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;

      &::placeholder {
        color: white;
        font-style: italic;
      }

      &:disabled {
        background-color: rgba(0, 0, 0, 0.5);
        color: darkgray;
      }
    }

    button {
      width: 100%;
      background-color: white;
      border: none;
      height: 40px;
      margin-top: 20px;
      cursor: pointer;
      font-size: 18px;
      transition: all 0.3s;

      &:disabled {
        opacity: 0.3;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
      }
    }

    .email-error {
      color: white;
      padding: 5px;
      margin-top: 15px;

      text-align: center;
    }

    p {
      font-size: 14px;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .validation-message {
      font-family: "Source Sans Pro Webfont", "Helvetica", "Arial", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: initial;
      text-transform: none;
      transition: height 0.15s ease-out, opacity 0.15s ease-out, margin 0.15s ease-out, padding 0.15s ease-out, height 0.15s ease-out, opacity 0.15s ease-out, margin 0.15s ease-out, padding 0.15s ease-out;
      display: block;
      float: right;
      margin-top: 10px;
      opacity: 1;
      padding: 11px 20px 11px 40px;
      text-align: left;
      width: 100%;

      &::before {
        font-size: 15px;
        margin-left: -25px;
        margin-right: 10px;
        position: relative;
        top: -.1em;
        border: 0;
        color: currentcolor;
        font-family: 'icomoon';
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        vertical-align: middle;
        content: '\e614';
      }
    }
  }
}

@media (min-width: 530px) {
  .new-user-page form .validation-message {
    margin-right: -100%;
    margin-top: 25px;
    padding-bottom: 1px;
    padding-top: 1px;
  }
}