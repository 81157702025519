.admin-card-container {
  //   flex: 1 0 17%;
  color: white;
  height: 50vh;
  padding: 25px;
  transition: background-size 0.15s ease-out, background-position 0.15s ease-out, background-size 0.15s ease-out,
    background-position 0.15s ease-out;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 130vh auto;
  text-decoration: none;
  position: relative;
  overflow: hidden;

  &.coral-reef {
    background-image: url("../../../illustrations/coral-reef.svg");
  }
  &.impact-radar {
    background-image: url("../../../illustrations/impact-radar.svg");
  }
  &.channel-treasures {
    background-image: url("../../../illustrations/channel-treasures.svg");
  }
  &.tide-table {
    background-image: url("../../../illustrations/tide-table.svg");
  }
  &.shark-attack {
    background-image: url("../../../illustrations/shark-attack.svg");
  }
  &.sea-turtle {
    background-image: url("../../../illustrations/sea-turtle.svg");
  }
  &.smartify {
    background-image: url("../../../illustrations/smartify.svg");
  }
  &.ai-credits {
    background-image: url("../../../illustrations/ai-credits.svg");
  }
  &.notifictation-manager {
    background-image: url("../../../illustrations/notification-manager.svg");
  }
  &.goals {
    background-image: url("../../../illustrations/goals.svg");
  }

  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    transition: text-decoration 1s;
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: initial;
    margin: 0px;
  }

  p {
    color: inherit;
    font-weight: 300;
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: initial;
    text-transform: none;
  }

  &:hover h2 {
    text-decoration: underline 0.5px;
    text-underline-offset: 5px;
  }

  &.disabled {
    color: #2b2b2b;
  }

  .disabled-overlay {
    position: absolute;
    width: 100%;
    height: inherit;
    background-color: rgba(255, 255, 255, 0.35) !important;
    color: black;
    top: 0px;
    left: 0px;
  }

  &.disabled:hover h2 {
    text-decoration: none !important;
    text-underline-offset: 5px;
  }

  &.coming-soon {
    background-color: rgba(0, 0, 0, 0.35) !important;
    color: #2b2b2b;
    pointer-events: none;
    cursor: default;

    &::after {
      content: "coming soon";
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: initial;
      text-transform: none;
      text-transform: uppercase;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: #fff;
      color: #0c5d56;
      display: block;
      height: 30px;
      padding-top: 18px;
      padding-bottom: 3px;
      position: absolute;
      right: -60px;
      text-align: center;
      top: 25px;
      width: 250px;
    }
  }
}
