.report-row-container {
  > * {
    font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
  }
  background-color: white;

  display: flex;
  background-color: #fff;
  padding: 6px 6px 2px;
  margin: 4px 4px;
  color: #8d8d8d;

  .report-row-subject {
    .report-row-subject-title {
      flex: 1;
      font-size: 16px;
      line-height: 20px;
      font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 300;
      color: #8d8d8d;
    }

    .report-row-subject-description {
      font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
      font-size: 6px;
      font-weight: 300;
      line-height: 8px;
      color: #8d8d8d;
      width: 80%;
    }
  }

  .report-row-values {
    display: flex;
    .report-row-value-container {
      padding-left: 5px;
    }

    .report-row-value-container-title p {
      width: 70px;
      font-size: 6px;
      font-weight: 300;
      color: black;
      margin: 0px;
      margin-bottom: 3px;
    }

    .report-row-value {
      display: flex;

      .report-row-value-digits-container {
        position: relative;
        top: -3px;
        left: 5px;

        .report-row-value-digit {
          font-size: 18px;
          font-weight: 300;
        }
        .report-row-percentage-diff {
          font-size: 6px;
          font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
          font-weight: 300;

          span {
            color: #d3d5da;
            &.green {
              color: #60d470;
            }
          }
        }
      }
    }
  }
}
