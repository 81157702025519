.user-modal-wrapper {
  background-color: rgb(255, 255, 255);
  width: 950px;
  padding: 20px;
  color: black;
  margin-top: 30px;
  .modal-title {
    font-family: "Roboto Slab Webfont", Halvetica;
    font-size: 26px;
    margin: 0px 0px 10px 5px;
    font-weight: 400;
  }

  .user-modal-container {
    display: flex;

    .user-modal-details {
      width: 350px;
      background-color: rgb(245, 245, 245);
      padding: 20px;
    }
  }
  .user-modal-brand-selector-container {
    padding-left: 20px;

    .user-modal-brand-selector-tabs {
      display: flex;

      div {
        flex-grow: 1;
        padding: 10px;
        text-align: center;
        border: 1px solid gainsboro;
        cursor: pointer;

        &.active {
          background-color: rgb(219, 219, 219);
        }
      }
    }

    .user-modal-brand-selector {
      margin-top: 10px;
      height: calc(100vh - 350px);
      width: 550px;
      overflow-y: scroll;
      border: 1px solid gainsboro;

      .brand-option {
        margin-bottom: 10px;
        padding: 5px;

        input[type="checkbox"] {
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
  }
  .user-modal-save-btn {
    margin-top: 10px;
    float: right;
  }

  .user-modal-submit {
    .so-error {
      margin-top: 6px;
      top: unset;
      text-align: right;
    }
  }
}
