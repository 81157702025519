.stories-settings-modal-wrapper {
  width: 1000px;
  background-color: white;
  margin-top: 30px;
  padding: 20px;
  height: calc(100vh - 120px);
  overflow-y: auto;

  .code-textarea {
    height: 200px;
    resize: none;
  }

  .stories-settings-modal-container {
    display: flex;
    justify-content: space-between;

    > div {
      width: 48%;
    }
    .right-column {
      padding-top: 45px;
    }

    .metric-title {
      font-weight: 400;
      text-decoration: underline;
    }

    .conversion-settings-container {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      div {
        width: 48%;
      }
    }

    .engagement-settings-container {
      display: flex;
      justify-content: space-between;
      div {
        width: 48%;
      }
    }

    .pageviews-settings-container {
      display: flex;
      justify-content: space-between;
      div {
        width: 31%;
        vertical-align: bottom;
      }
    }

    .show-story-cpi-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        margin-right: 20px;
      }
    }
  }
}
