// scss-lint:disable PlaceholderInExtend

$icomoon-font-grid-size: 24px;

@font-face {
  font-family: "icomoon";

  src: url("../../fonts/icomoon/fonts/icomoon.eot?q60q5m");
  src: url("../../fonts/icomoon/fonts/icomoon.eot?q60q5m#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon/fonts/icomoon.woff?q60q5m") format("woff"),
    url("../../fonts/icomoon/fonts/icomoon.ttf?q60q5m") format("truetype"),
    url("../../fonts/icomoon/fonts/icomoon.svg?2bhlgp#icomoon") format("svg");

  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  border: 0;
  color: currentcolor;
  font-family: "icomoon";
  font-size: $icomoon-font-grid-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  vertical-align: middle;
}

.icon-modal-close::before {
  content: "\e914";
}

.icon-edit::before {
  content: "\e915";
}

.icon-arrow-down-closed::before {
  content: "\e916";
}

.icon-menuitem-insights::before {
  content: "\e917";
}

.icon-arrow-up-closed::before {
  content: "\e918";
}

.icon-checkmark-tentacles::before {
  content: "\e911";
}

.icon-pencil-tentacles::before {
  content: "\e90b";
}

.icon-headertest-tentacles::before {
  content: "\e90c";
}

.icon-arrow-down-tentacles::before {
  content: "\e90d";
}

.icon-arrow-up-tentacles::before {
  content: "\e90e";
}

.icon-arrow-left-tentacles::before {
  content: "\e90f";
}

.icon-arrow-right-tentacles::before {
  content: "\e910";
}

.icon-feedtype-tentacles::before {
  content: "\e909";
}

.icon-arrow-left::before {
  content: "\e600";
}

.icon-arrow-right::before {
  content: "\e601";
}

.icon-button-filters::before {
  content: "\e602";
}

.icon-button-follow::before {
  content: "\e603";
}

.icon-button-settings::before {
  content: "\e604";
}

.icon-feedbutton-ink::before {
  content: "\e605";
}

.icon-feedtype-act::before {
  content: "\e606";
  text-align: left;
}

.icon-feedtype-comment::before {
  content: "\e607";
  text-align: left;
}

.icon-feedtype-tip::before {
  content: "\e608";
  text-align: left;
}

.icon-feedtype-update::before {
  content: "\e609";
  text-align: left;
}

.icon-menuitem-inked::before {
  content: "\e60a";
}

.icon-menuitem-current::before {
  content: "\e60b";
}

.icon-menuitem-stories::before {
  content: "\e60c";
}

.icon-menuitem-stream::before {
  content: "\e60d";
}

.icon-smartocto::before {
  content: "\e912";
}

.icon-socialchannel-facebook::before {
  content: "\e60f";
}

.icon-socialchannel-instagram::before {
  content: "\e610";
}

.icon-socialchannel-twitter::before {
  content: "\e611";
}

.icon-spinner::before {
  content: "\e612";
}

.icon-timezone-night::before {
  content: "\e613";
}

.icon-validation-error::before {
  content: "\e614";
}

.icon-button-menu::before {
  content: "\e615";
}

.icon-socialchannel-home::before {
  content: "\e616";
}

.icon-socialchannel-link::before {
  content: "\e617";
}

.icon-socialchannel-newsletter::before {
  content: "\e618";
}

.icon-socialchannel-youtube::before {
  content: "\e619";
}

.icon-feedtype-act1::before {
  content: "\e61a";
}

.icon-feedtype-act2::before {
  content: "\e61b";
}

.icon-unlocked::before {
  content: "\e61c";
}

.icon-search::before {
  content: "\e61f";
}

.icon-arrow-up::before {
  content: "\e620";
}

.icon-arrow-down::before {
  content: "\e621";
}

.icon-origin::before {
  content: "\e622";
}

.icon-menuitem-bundle::before {
  content: "\e900";
}

.icon-hexagon::before {
  content: "\e901";
}

.icon-plus::before {
  content: "\e902";
}

.icon-disc::before {
  content: "\e903";
}

.icon-delete::before {
  content: "\e904";
}

.icon-socialchannel-linkedin::before {
  content: "\e905";
}

.icon-desktop-monitor::before {
  content: "\e906";
}

.icon-smartphone-call::before {
  content: "\e907";
}

.icon-pushmessage::before {
  content: "\e908";
}

.icon-messenger::before {
  content: "\e90a";
}

.icon-menuitem-videos::before {
  content: "\e913";
}

.icon-clock::before {
  content: "\e94e";
}

.icon-menuitem-admin::before {
  content: "\e997";
}

.icon-save::before {
  content: "\e962";
}

.icon-copy::before {
  content: "\e925";
}

.icon-eye::before {
  content: "\e9ce";
}

.icon-menuitem-logbook::before {
  content: "\f02d";
}
