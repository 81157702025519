@import "../../../assets/stylesheets/variables.scss";

.bar-legend {
  display: flex;
  font-size: 14px;

  .legend-column {
    padding: 10px;
  }

  .legend-indicator {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    margin-right: 6px;
  }

  .legend-label {
    font-family: "Source Sans Pro Webfont";
    color: $color-gray;
    font-size: 14px;
    font-weight: 300;
  }
}
