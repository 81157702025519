.notifiers-actions-container {
  padding: 0px 20px;

  .so-button {
    margin: 0px 5px;
  }
}

.notifiers-actions-response {
  margin-top: 16px;
  max-width: 620px;
  word-wrap: break-word;
}
