@import "../../assets/stylesheets/variables.scss";

.stream-table-container {
  h3 {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: initial;
    text-transform: none;
    -webkit-transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    -moz-transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    border-bottom: 1px solid #979797;
    margin: 20px 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }

  td {
    border: 0px;
    background-color: white;
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: initial;
    text-transform: none;
    max-width: 30em;

    &.icon-cell {
      width: 50px !important;
      text-align: center;

      .checkmark-icon {
        font-family: "icomoon";
        width: 15px;
        &::before {
          content: "\e911";
          font-size: 20px;
        }
      }
    }
  }

  .show-more {
    width: 100%;
    background-color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 300;
      color: #1abcae;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .stream-table-loader-container {
    background-color: white;
    padding: 20px;
  }

  td {
    border: 0px;
    border-collapse: collapse;
    border-left: 0px !important;
    border-bottom: 0px !important;
  }

  .stream-table-comment-btn {
    margin: 15px 0;
    float: right;
  }

  .stream-table-comment-box {
    border: 2px solid transparent;
    border-radius: 3px;
    display: block;
    padding: 9px 15px;
    background-color: #fff;
    border-width: 0;
    color: #000;
    font-size: 14px;
    height: 100px;
    margin-bottom: 0;
    width: 100%;

    &::placeholder {
      color: rgba(#000, 0.5);
      font-style: italic;
    }

    &:focus {
      border-color: transparent;
      outline: none;
      background-color: #fff;
      color: #000;
    }
  }

  .stream-table-author {
    font-size: 14px;
    margin-top: 10px;
  }

  button:disabled {
    cursor: default;
    opacity: 0.3;

    &:hover {
      cursor: default;
    }
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .stream-table-container {
    width: 100%;
    overflow: auto;
  }
}
