.so-input {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;

  padding: 9px 15px;
  border-width: 0px;
  border: 1px solid gainsboro;

  &--dark {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;

    &::placeholder {
      color: rgba(255, 255, 255, 0.651);
    }
  }
}

.so-input-fullwidth {
  width: 100%;
}

.so-error {
  color: #e63656;
  font-style: italic;
  position: relative;
  top: -15px;
}