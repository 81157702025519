.so-pill {
    display: inline-block;
    padding: 2.5px 5px;
    border: 1px solid rgb(175, 175, 175);
    margin: 2.5px;
    border-radius: 5px;

    span {
        &:hover {
            color: red !important
        }
    }
}