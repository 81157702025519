.so-select {
  width: 100px;
  position: relative;

  &.full-width {
    width: calc(100% - 0px);
  }

  .select-value-container {
    width: inherit;
    padding: 10px;
    position: relative;
    height: 40px;
    border: 1px solid gainsboro;
    cursor: pointer;
    background-color: white;

    &.disabled {
      background: transparent;
    }

    &.options-displayed {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .select-value {
      // width: 80px;
      width: inherit;
      display: block;
      overflow: hidden;
    }

    .select-search {
      position: absolute;
      // top: -45px; /* Place the search input over the select box */
      width: 100%;
      padding-left: 10px;
      left: 0px;
      border: none;
      z-index: 1;
      font-size: medium;
      
      &::placeholder {
        font-size: medium;
      }

      /* Make sure the search input stays above the options */
      &:focus {
        outline: none;
      }
    }

    .dropdown-icon {
      position: absolute;
      right: 4px;
      top: 25%;
      z-index: 2;
      background-color: white;

      &.disabled {
        background-color: transparent;
      }
      svg {
        transition: all 0.3s;
        transform: rotate(90deg);
      }

      width: 20px;
      text-align: center;
    }

    &.rounded {
      border-radius: 20px;
    }
  }

  .select-value-container.options-displayed {
    .dropdown-icon {
      svg {
        transform: rotate(270deg);
      }
    }
  }

  .select-options {
    position: absolute;
    background-color: white;
    width: inherit;
    top: 100%;
    /* Ensure it starts below the select-value-container */
    // z-index: 1; /* Make sure it is on top of other elements */
    border: 1px solid gainsboro;
    /* Adding a border so it looks similar to the value container */

    &.rounded {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &.options-hidden {
      display: none;
    }

    &.options-displayed {
      display: block;
    }
  }

  .disabled {
    background-color: #fafafa;
    color: #59595f;
  }

  .option {
    padding: 10px;
    border: 1px solid gainsboro;
    cursor: pointer;

    &:hover {
      background-color: gainsboro;
    }
  }

  .option.rounded:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}