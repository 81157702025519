.nav-filters-container {
  color: white;
  width: 100%;
  background-color: #387292;

  .nav-filters-animation-container {
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-in;

    &.active {
      max-height: 500px;
      overflow: visible;
      opacity: 1;
      transition: all 0.3s ease-in;
    }
  }

  .nav-filters-header {
    display: flex;
    background-color: #4381aa;
    justify-content: space-between;

    .nav-filters-title {
      padding: 10px 15px;
      font-size: 20px;
      font-weight: 600;
      line-height: 35px;
    }

    .nav-filters-selected {
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
      padding: 0px 12px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.4);
      text-transform: capitalize;
      margin-left: 10px;

      .nav-remove-filter {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: small;
        color: gainsboro;
        margin-left: 10px;
      }

      &:first-of-type {
        margin-left: 5px;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .nav-filters-menu {
    padding: 0px 15px;
    width: 100%;
    max-height: 0;
    overflow: visible;
    transition: max-height 2s ease-out;

    &.active {
      max-height: 100%;
      padding-bottom: 24px;
    }

    .pill {
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
      padding: 6px 15px;
      background-color: rgba(0, 0, 0, 0.2);
      margin: 0px 10px 10px 0px;
      border-radius: 16px;
      cursor: pointer;

      &.active {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .nav-filters-sections-container {
    margin-top: 16px;

    .nav-filters-sections {
      margin-top: 8px;
    }
  }

  .so-select__control {
    background-color: rgba(0, 0, 0, 0.2);
    transition: border-color 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out,
      border-color 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
    color: #fff;

    border-color: transparent;
    border-radius: 0;
    border-style: solid;
    border-width: 2px;

    &:hover {
      border-color: transparent;
      border-radius: 0;
      border-style: solid;
      border-width: 2px;
      outline: 0;
    }

    &--is-focused {
      border-color: transparent;
      border-radius: 0;
      border-style: solid;
      border-width: 2px;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.3);
      outline: 0;
    }

    svg {
      fill: #fff;
    }
  }

  .so-select__menu {
    color: #fff;
    background-color: #284d66;

    .so-select__option {
      background-color: #284d66;
      transition: background-color 0.15s ease-out;

      &:hover {
        background-color: #000;
      }
    }
  }

  .so-select__multi-value {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;

    &__label {
      color: white;
    }

    &__remove:hover {
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
    }
  }
}
