@import "../../assets/stylesheets/variables.scss";

.current-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  > * {
    box-sizing: border-box;
  }
  .full-screen-btn-container {
    position: absolute;
    right: 145px;
    top: 13px;
  }
  .current-iframe {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    height: 100vh;
    border: none;
    display: block;
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .full-screen-btn-container {
    display: none;
  }
}
