@import "../../assets/stylesheets/variables.scss";

.admin-page-title {
  padding: 30px 20px;
  padding-bottom: 0px;
  margin-top: 0px;
  font-family: "Roboto Slab Webfont", Helvetica, sans-serif;
  font-weight: 400;
  color: #4a4a4a;
  font-size: 30px;
}

.admin-page-header-btns {
  display: flex;
}

.cards-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  background-image: linear-gradient(to bottom, #54a1d4 0, #1abcae 88%);
  padding-top: $main-navigation-height;
  transition: padding-left 0.3s;
}

.table-container {
  padding-top: 10px;
  padding: 20px;
}

.loader-container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-button-icon {
  display: inline-block;
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  margin: 2.5px !important;
  border-radius: 50% !important;
}

.users-table-actions-container {
  .so-button {
    margin: 0px 2.5px;
  }
}

@media screen and (max-width: 1300px) {
  .cards-container {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}

@media screen and (max-width: 1000px) {
  .cards-container {
    grid-template-columns: repeat(auto-fill, minmax(33.33333%, 1fr));
  }
}

@media screen and (max-width: 770px) {
  .cards-container {
    padding-top: 0px;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));

    &.mobile-menu-shown {
      padding-left: 80px;
      padding-top: 0px;
      transition: padding-left 0.5s;
    }
  }
}

@media screen and (max-width: 550px) {
  .cards-container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
