$color-blue: rgb(84, 161, 212);
$color-darker-blue: rgb(67, 129, 166);
$color-green: rgb(26, 188, 174);  // 1ABBAE
$color-darkgreen: rgb(12, 93, 86);
$color-lightgreen: rgb(210, 241, 239);

.modal-wrapper {
    position: fixed;
    min-height: 100%;
    width: 100%;
    background-color: rgba(23, 129, 125, 0.9);
    z-index: 999;
    top: 60px;
    -webkit-animation: bgcolor 20s infinite;
    animation: bgcolor 10s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    .modal-header {
        position: absolute;
        top: 30px;
        right: 30px;
        color: white;
        cursor: pointer;
        font-size: 30px;
    }

    .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@keyframes bgcolor {
    0% {
        background-color: shade($color-blue, 30%);
      }
    
      50% {
        background-color: shade($color-green, 30%);
      }
    
      100% {
        background-color: shade($color-blue, 30%);
      }
}

