.notification-settings {
  margin: 30px auto;
  width: 95%;
  font-family: "Source Sans Pro Webfont";

  .notification-settings-table {
    width: 100%;
    margin-bottom: 35px;
    align-items: center;

    .notification-settings-table-navigation {
      display: flex;
      margin-top: 20px;
      background-color: rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .notification-settings-table-nav-item {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 15px;
        height: 50px;
        cursor: pointer;
        transition: background-color 0.15s ease-out;

        &.active {
          background-color: white;
        }

        &.disabled {
          color: #d3d2d2;
        }

        &:hover {
          background-color: aliceblue;
        }

        .notification-settings-table-badge {
          font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: initial;
          text-transform: none;
          background-color: #f45642;
          border-radius: 8px;
          color: #fff;
          display: inline-block;
          height: 16px;
          min-width: 16px;
          overflow: hidden;
          padding: 0 3px;
          margin-left: 4px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }

    .notification-settings-table-items {
      height: 300px;
      overflow: hidden;
      overflow-y: scroll;
      background-color: #faf9f8;
      scrollbar-color: #000 transparent;
      scrollbar-width: thin;

      .notification-settings-table-item {
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #000;

        .notification-settings-table-item-title {
          flex: 1;
        }

        .notification-settings-table-item-details {
          display: flex;

          .notification-settings-table-detail {
            display: flex;
            margin-right: 16px;

            input[type="checkbox"] {
            }

            label {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  h2 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 15px 0;
  }
}
