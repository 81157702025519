.stacked-area-graph-container {
  position: relative;

  .scroll-lock-overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .click-to-interact {
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      padding: 5px 10px;
      background-color: rgba(128, 128, 128, 0.8);
      color: white;
      font-size: 14px;
    }
  }

  .lock-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    // background-color: black;
    color: black;
    font-family: "icomoon";
    cursor: pointer;

    &:hover {
      color: darkgrey;
    }

    &::after {
      content: "\e61c";
    }
  }
}
