.tentacles-classifications-form-container,
.tentacles-settings-form-container {
  .textarea-code-editor {
    height: 200px;
    resize: none;
    border-color: lightgrey;
    position: relative;
    top: -5px;
  }
}
