$color-green: rgb(26, 188, 174); // 1ABBAE

.stream-table-detail {
  margin: 15px;
  text-align: right;

  a {
    color: inherit;
    display: inline-block;
    width: 100%;
    text-decoration: none;

    &:hover {
      color: $color-green;

      svg {
        fill: $color-green;
      }
    }
  }

  .stream-table-detail-label {
    margin-right: 4px;
    font-size: 14px;
  }

  .stream-table-detail-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
}

@media screen and (max-width: 550px) {
  .stream-table-detail {
    margin: 0 15px 15px 60px;
    width: 100%;
  }
}