.nav-list-item {
  list-style-type: none;
  padding: 20px;
  color: white;
  border-bottom: 1px solid black;
  min-height: 140px;
  cursor: pointer;
  position: relative;

  &.active {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .nav-list-item-container {
    display: flex;

    .icon-container {
      width: 50px;

      .red-icon {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        background-color: #f45642;
        margin: 0px 3px;
      }

      .white-icon {
        width: 15px;

        &::before {
          content: "\e609";
          text-align: left;
        }
      }

      .checkmark-icon {
        width: 15px;
        &::before {
          content: "\e911";
          font-size: 20px;
        }
      }
    }

    .details-container {
      position: relative;
      width: 100%;

      .bundles-story-count {
        position: absolute;
        right: 0px;
        top: 0px;

        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: initial;
        text-transform: none;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 12.5px;
        color: #fff;
        font-weight: 400;
        height: 25px;
        line-height: 25px;
        margin-top: -4px;
        min-width: 35px;
        padding: 0 10px;
        text-align: center;
      }

      .description {
        font-size: 14px;
        color: gainsboro;
        padding: 10px 0px;
      }

      .description-primary {
        font-size: 13px;
        font-weight: 300;
        min-height: 30px;
        margin-bottom: 15px;
        max-height: 40px;
        opacity: 0.6;
      }

      .title-container--primary {
        border-left: 1px solid white;
        padding: 0px 10px;

        .time {
          padding-top: 10px;
          font-size: 12px;
          color: gainsboro;
        }

        .title--primary {
          font-family: sans-serif;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
        }
      }

      .title-container--secondary {
        border-left: 0px;

        .title--secondary {
          font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: initial;
          text-transform: none;
        }

        .secondary-icons {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .time {
            font-size: 11px;
            color: #ffffffd9;
            margin-right: 10px;
          }

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .paid {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #f45642 transparent transparent;

    &:after {
      width: 0;
      height: 0;
      color: #fff;
      content: "\20AC";
      position: relative;
      left: 30px;
      top: 5px;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .ink-btn-container {
    transform: rotate(180deg);
    height: 15px;
    position: absolute;
    bottom: 10px;
    right: 10px;

    svg {
      fill: rgba(0, 0, 0, 0.2);
    }

    &:hover svg {
      fill: rgba(0, 0, 0, 0.6);
    }

    &.active {
      svg {
        fill: #fff;
      }
    }
  }
}
.nav-list-day {
  list-style-type: none;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.3) !important;
  font-family: "Source Sans Pro Webfont", Halvetica, Arial, sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.pushmessage-icon {
  font-family: "icomoon";
  margin-right: 5px;
  &::before {
    content: "\e908";
  }
}

.tweeter-icon {
  font-family: "icomoon";
  margin-right: 5px;
  &::before {
    content: "\e611";
  }
}

.site-link-icon {
  font-family: "icomoon";
  margin-right: 5px;
  &::before {
    content: "\e617";
  }
}

.linkedin-icon {
  font-family: "icomoon";
  margin-right: 5px;
  &::before {
    content: "\e905";
  }
}

.origin-icon {
  font-family: "icomoon";
  margin-right: 5px;
  &::before {
    content: "\e622";
  }
}
