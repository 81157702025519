@import "../assets/stylesheets/variables.scss";

.data-not-available-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: $main-navigation-height;
  background-color: #f3f1ed;

  .data-not-available-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: -50px;

    .no-data-icon-container {
      position: relative;
      left: -5px;
    }

    .no-items-message {
      color: #145456;
      font-size: 20px;
    }

    .no-items-stay-alert {
      color: #145456;
      font-size: 24px;
      position: relative;
      top: -20px;
    }

    .no-items-suggestion {
      color: #145456;
      font-size: 20px;
      position: relative;
      top: -30px;

      a {
        color: #145456;
      }
    }

    animation: noDataMessageAnimation 0.3s;
    animation-timing-function: ease-in;
  }

  .mailto-link {
    color: #145456;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@keyframes noDataMessageAnimation {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: -50px;
    opacity: 1;
  }
}
