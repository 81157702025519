.circle-graph {
  text-align: center;

  &.positive {
    .full-circle {
      fill: rgb(26, 188, 174);
    }

    .part-circle {
      fill: none;
      stroke: rgb(243, 241, 237);
      stroke-width: 1.5;
    }
  }

  &.negative {
    .full-circle {
      fill: rgb(243, 241, 237);
    }

    .part-circle {
      fill: rgb(26, 188, 174);
    }
  }
}

.hexagon-graph {
  position: relative;
  top: 40px;
  left: -50px;
  transform: rotate(90deg);

  &.positive {
    .full-hex {
      fill: rgb(26, 188, 174);
    }

    .part-hex {
      fill: none;
      stroke: rgb(243, 241, 237);
      stroke-width: 1.5;
    }
  }

  &.negative {
    .full-hex {
      fill: rgb(243, 241, 237);
    }

    .part-hex {
      fill: rgb(26, 188, 174);
    }
  }
}
