.conversions {
  &.performance {
    margin-bottom: 0px !important;
  }
}

.conversions-performance-subtitle {
  font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: initial;
  text-transform: none;
  margin: 0px;
}
.conversions-performance-value {
  font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: initial;
  text-transform: none;
  margin-top: 0px;
}
