.mail-report-container {
  background-color: #eaecf1;
  color: #454545;
  max-width: 125mm;
  margin: auto;

  .mail-report-header {
    display: flex;

    .mail-report-header-left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      h1 {
        font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
        font-size: 30px;
        color: #d3d5da;
        padding: 0px;
        margin: 0.15em 0;
        line-height: 20px;
      }
      h2 {
        font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
        font-size: 30px;
        line-height: 37.5px;
        color: #d3d5da;
        font-weight: lighter;
        margin: 0px;
        padding: 0px;
        span {
          text-transform: capitalize;
        }
      }
      .date {
        font-size: 6px;
        font-weight: 300;
        line-height: 6px;
      }
    }
    .mail-report-header-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      align-self: stretch;
      padding: 0.5em;
      .logo {
        width: 60px;
        height: 8px;
        background-image: url("../../assets/images/smartocto-logo.svg");
      }
      .for {
        font-size: 6px;
        height: 22px;
        margin: 0px;
      }
      .brand-logo img {
        width: 20px;
      }
    }
  }

  .mail-report-footer {
    font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
    background-color: #eaecf1;

    color: #8d8d8d;
    padding: 30px 5px;
    .logo {
      width: 91px;
      height: 12px;
      background-image: url("../../assets/images/smartocto-logo.svg");
      margin-bottom: 10px;
    }

    .footer-info-container {
      display: flex;

      div {
        width: 33%;
      }
      p {
        font-size: 8px;
        line-height: 8px;
        font-weight: 300;
        margin: 0px;

        &.hidden {
          opacity: 0;
        }
      }
    }
  }
}
