.table-tools {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  position: sticky;
  top: 70px;
  padding: 5px 0px;
  z-index: 90;
  background-color: white;

  > div {
    margin-left: 40px;
  }

  .table-entries-number {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .select-options.options-displayed {
    z-index: 999 !important;
  }
}

table.so-table {
  width: 100% !important;
  max-width: 100% !important;
  font-family: "Source Sans Pro Webfont";
  font-weight: 300;
  display: table;
  border-collapse: collapse;
  border: 1px solid gainsboro;
  position: relative;

  thead {
    position: sticky;
    top: 118px;
    z-index: 89;
    border-bottom: 1px solid black !important;
    tr,
    th {
      border-bottom: 1px solid black !important;
    }
  }

  th {
    background-color: #e0e0e0;
    text-transform: capitalize;
    font-weight: 600;

    td {
      background-color: rgb(95, 16, 16);
    }

    a.header-sortable {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
    }

    .row-title {
      display: inline-block;
      text-decoration: inherit;
    }

    .dropdown-icon {
      display: inline-block;
      margin-left: 5px;
      position: relative;
      top: 5px;
      &.order-arrow-none {
        display: none;
      }

      &.order-arrow-asc {
        svg {
          transform: rotate(90deg);
        }
      }
      &.order-arrow-desc {
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }

  tr {
    transition: all 1s;
  }

  tr:nth-of-type(odd) {
    background-color: #f2f2f2;
  }

  td,
  th {
    margin: 0px;
    border: 0px;
    text-align: left;
    padding: 10px 18px;
    border-left: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
    max-width: 100%;
    width: auto !important;
    text-wrap: wrap;
  }

  td {
    width: 1px;
  }

  .table-actions-container {
    display: flex;
  }
}
