.umbrella-modal-wrapper {
  background-color: white;
  width: 1400px;
  padding: 20px;
  margin-top: 50px;

  .umbrella-modal-title {
    font-family: "Roboto Slab Webfont", Halvetica;
    font-size: 26px;
    margin: 0px 0px 10px 5px;
    font-weight: 400;
  }

  .umbrella-modal-container {
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .column {
      width: 48%;
    }

    .text-area-wrapper {
      margin-bottom: 20px;
      p {
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
    .textarea-code-editor {
      height: 150px;
      resize: none;
    }

    .brands-selector-container {
      display: flex;
      justify-content: space-between;

      .all-brands-wrapper {
        width: 48%;
        .all-brands-container {
          height: 300px;
          overflow-x: hidden;
          overflow-y: scroll;
          border: 1px solid gainsboro;
        }
      }

      .selected-brands-wrapper {
        width: 48%;

        .selected-brands-container {
          height: 300px;
          border: 1px solid gainsboro;
        }
      }

      .brand-label {
        padding: 3.5px 10px;
        cursor: pointer;
        &:hover {
          background-color: rgb(26, 188, 174);
          color: white;
        }
      }
    }

    .action-description {
      padding: 20px 0px;
    }
  }
}
