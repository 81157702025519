.spark-graph {
  position: absolute;
  right: 0px;
  bottom: 0px;

  .axis-y {
    visibility: hidden;
  }

  .story {
    fill: rgba(0, 0, 0, 0);
  }
}
