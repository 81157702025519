@import "./assets/stylesheets/variables.scss";

* {
  box-sizing: border-box;
}

.main-page-container {
  width: calc(100% - 280px);
  position: relative;
  left: 280px;
  top: $main-navigation-height;
}

.page-container-no-side-nav {
  padding-top: $main-navigation-height;
}

@media screen and (max-width: 770px) {
  .main-page-container {
    left: 0px;
    top: 50px;
    width: 100%;
  }
}
