.recommender-page-wrapper {
  padding: 80px 30px;
  background-color: #f3f1ed;
  position: relative;

  .recommender-loading-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .recommender-page-title {
    font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 2em;
    margin: 0.67em 0;
  }
  .recommender-page-subtitle {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: initial;
    text-transform: none;
  }

  .recommender-container {
    display: flex;
    .recommender-form {
      width: 60%;
      padding-top: 40px;

      h2 {
        margin: 0px;
        padding: 0px;
        font-size: 18px;
        margin-bottom: 5px;
        font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
        font-weight: 400;
      }
    }

    textarea {
      font-family: "Source Sans Pro Webfont";
      resize: vertical;
    }

    .headline-input {
      min-height: 100px;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
      background-color: #fff;
      display: block;
      margin-bottom: 20px;
      width: 100%;
      padding: 15px;
      border: 0px;
    }

    .article-input {
      background-color: #fff;
      display: block;
      margin-bottom: 20px;
      width: 100%;
      padding: 15px;
      min-height: 150px;
      border: 0px;
      font-size: 16px;
    }

    .headline-output {
      min-height: 100px;
      background-color: #fff;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 18px;
      line-height: 25px;
    }

    .timing-output {
      min-height: 100px;
      background-color: #fff;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .recommender-control {
    flex-grow: 1;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;

    .switch-title,
    .actions-title {
      font-family: "Source Sans Pro Webfont";
      font-size: 16px;
      font-weight: 700;
    }

    .output-actions-container {
      button {
        display: block;
        width: 200px;
        margin-bottom: 10px;
      }
    }
  }

  .info {
    h2 {
      margin: 0px;
      padding: 0px;
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: 20px;
      font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
      font-weight: 400;
    }
  }
}
