@import "../../assets/stylesheets/fonts/_all.scss";

.story-container {
  flex-grow: 1;
  background-color: #f3f1ed;
  padding-bottom: 100px;

  header {
    padding: 15px 15px 20px;

    .title-loader-container {
      height: 107px;
      overflow: hidden;

      svg {
        position: relative;
        top: -10px;
      }
    }

    .so-button {
      font-size: 25px;
      padding: 2px;
      height: 30px;
      line-height: 20px;
      width: 30px;
    }

    p,
    h1 {
      color: #4a4a4a;

      a {
        text-decoration: none;
        color: #4a4a4a;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    p.story-date {
      font-size: 16px;
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      font-weight: 300;
      margin: 0px;
    }

    p.paid-article {
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      margin: 0px;
    }

    h1 {
      max-width: 25em;
      font-size: 30px;
      line-height: 34px;
      font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .bundles-control-container {
      display: flex;

      .bundles-container {
        display: flex;
        margin-right: 15px;
        .bundle-pill {
          // border: 1px solid gainsboro;
          position: relative;
          top: 6px;
          margin-right: 5px;
          .bundle-name {
            padding: 5px 10px;
            background-color: white;
            border: 1px solid gainsboro;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            &:hover {
              background-color: gainsboro;
              cursor: pointer;
            }

            a {
              text-decoration: none;
              color: inherit;
            }
          }
          .remove-bundle {
            padding: 5px 10px;
            background-color: white;
            border: 1px solid gainsboro;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            &:hover {
              background-color: gainsboro;
              color: red;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .video-graph-explanation {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-style: italic;
    color: #1abcae;
    font-weight: 300;
    margin-bottom: 0px;
  }

  main {
    padding: 15px;
  }
}
