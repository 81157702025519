@import "../../assets/stylesheets/variables.scss";

.smartocto-ai-loading-page {
  background-color: #eeede7;
  position: fixed;
  width: 100%;
  height: 100vh;
  padding-top: $main-navigation-height;
  display: flex;
  align-items: center;
  justify-content: center;

  .smartocto-ai-loader-container {
    img {
      width: 350px;
    }
  }
}
