.profile-notifications-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.profile-notification-container {
  padding: 10px 0px;
  margin: 10px;
  background-color: rgba(0,0,0,0.1);
  padding: 10px;
  flex-grow: 1;
  
  .input {
    margin-bottom: 10px;
  }

  .toggle-enable-button {
    padding: 15px;
    margin-bottom: 8px;
  }

  .validation-message {
    color: #fcdde3;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 8px;
    max-width: 350px;
  }
}
