.ai-modal-wrapper {
  background-color: white;
  padding: 20px;
  width: 900px;
  margin-top: 50px;

  .columns-container {
    display: flex;

    .column-left {
      margin-right: 15px;
      padding-top: 20px;
    }
    .column-right {
      margin-left: 15px;
    }

    .column-left,
    .column-right {
      flex-grow: 1;
    }
  }

  .creadit-deals-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    padding: 2px;
    border-radius: 2px;

    .credit-deal-header,
    .credit-deal-footer,
    .credit-deal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px;
      border-bottom: 1px solid #ddd;
    }

    .credit-deal-header {
      font-weight: bold;
      background-color: #f9f9f9;
      padding-left: 10px;
    }

    .credit-deal-body {
      max-height: 230px;
      overflow-y: scroll;
    }

    .credit-deal-footer {
      border-bottom: none;
    }

    .credit-deal-date,
    .credit-deal-credits {
      flex: 1;
      text-align: center;
    }

    .credit-deal-credits {
      & input {
        width: 90px;
      }
    }

    .credit-deal:last-child {
      border-bottom: none;
    }

    & > button:disabled,
    & > button[disabled] {
      cursor: not-allowed;
    }
  }

  .goals-northstarts-container {
    display: flex;

    > div {
      padding: 10px;
    }
  }

  .switch-wrapper {
    display: flex;

    .switch-container {
      padding: 0px 10px 15px 0px;
    }

    .switch-title {
      text-transform: capitalize;
    }
  }
}
