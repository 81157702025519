.stream-table-navigation {
  width: 100%;
  align-items: center;
  background-color: #faf9f8;
  display: flex;
  margin-top: 20px;
  font-family: "Source Sans Pro Webfont";

  .stream-table-nav-item {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 15px;
    height: 50px;
    cursor: pointer;

    &.active {
      background-color: white;
    }

    &.disabled {
      color: #d3d2d2;
    }

    &:hover {
      background-color: aliceblue;
    }
  }
}
