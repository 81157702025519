$main-navigation-height: 70px;

$main-navigation-mobile-width: 80px;

$mobile-navigation-responsive-break-point: 770px;

$sourcesanspro-font-stack: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
$robotoslab-font-stack: "Roboto Slab Webfont", "Helvetica", "Arial", sans-serif;

$page-background-color: #f3f1ed;
$color-gray: #4a4a4a;
$color-light-gray: #979797;
