.edit-displayed-columns-modal {
    margin-top: 150px;
    background-color: white;
    padding: 30px 50px;

    .item {
        margin-top: 5px;
        font-size: 20px;
    }

    input {
        transform: scale(1.3);
        margin-right: 10px;
    }
}