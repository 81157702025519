.edit-brand-modal-wrapper {
  background-color: rgb(255, 255, 255);
  width: 950px;
  padding: 20px;
  color: black;
  margin-top: 30px;
  height: calc(100vh - 110px);
  overflow-y: auto;

  .modal-title {
    font-family: "Roboto Slab Webfont", Halvetica;
    font-size: 26px;
    margin: 0px 0px 10px 5px;
    font-weight: 400;
  }
  .edit-brand-container {
    display: flex;

    .edit-brand-details {
      width: 350px;
      background-color: rgb(245, 245, 245);
      padding: 20px;

      .brand-active-switch-container {
        margin-bottom: 20px;

        .toggle-switch-label {
          margin-right: 10px;
        }

        .switch {
          position: relative;
          top: -2px;
        }
      }
      .drop-select {
        margin-bottom: 20px;
        label {
          display: block;
          margin-bottom: 10px;
        }
        .timezone-options-dropdown {
          z-index: 9999;
          height: 300px;
          overflow: hidden;
          overflow-y: scroll;
        }
      }
    }
  }

  .brand-features {
    padding: 0px 10px;
    h3 {
      font-family: "Roboto Slab Webfont", Halvetica;
      font-weight: 400;
      margin: 0px;
      padding: 5px 10px;
      background-color: gainsboro;
    }

    .features-list {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      width: 270px;

      .feature-item {
        padding: 2px 10px;
        text-transform: capitalize;
        border: 1px solid gainsboro;
        border-bottom: 0px;
        width: 100%;
        display: flex;
        vertical-align: middle;

        &:last-of-type {
          border-bottom: 1px solid gainsboro;
        }

        .feature-name {
          padding-top: 10px;
          width: 160px;
        }
        .feature-toggle {
          padding-top: 10px;
          width: 55px;
        }
        .feature-settings-icon {
          padding-top: 3px;
          cursor: pointer;

          .height-corrector {
            height: 35px;
            width: 20px;
          }
        }
      }
    }
  }

  .other-settings {
    flex-grow: 1;

    .brand-logo-wrapper {
      display: flex;
      margin-bottom: 20px;
    }
    .brand-logo-container {
      width: 70px;
      height: 70px;
      margin: auto;
      text-align: center;
      margin-bottom: 10px;

      img {
        border: 1px solid gainsboro;
        width: 100%;
      }
    }
    .select-logo {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      input[type="file"] {
        visibility: hidden;
        position: absolute;
      }

      label {
        background-color: #1abcae;
        color: white;
        padding: 10px 15px;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    .brand-settings-title {
      padding: 10px;
      border: 1px solid gainsboro;
      width: 100%;
      border-top: 0px;
      &:hover {
        background-color: gainsboro;
        cursor: pointer;
        user-select: none;
      }

      &.first {
        border-top: 1px solid gainsboro;
      }
    }
    .brand-settings-container {
      height: 0px;
      transition: all 0.5s;
      overflow: hidden;
      border: 1px solid gainsboro;
      border-top: 0px;
      &.active {
        height: 390px;
      }
      &.statistics.active {
        height: 250px;
      }
      &.copy-configuration.active {
        transition: all 0s;
        overflow: visible;
      }

      .domain-status-wrapper {
        padding: 10px;
        p {
          margin: 0px;
          margin-bottom: 5px;
        }

        .domain-status-container {
          display: flex;

          .domain-status-item {
            width: 70px;

            .domain-status-item-title {
              font-style: italic;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .copy-configuration-wrapper {
      padding: 10px;

      .drop-select {
        margin-bottom: 20px;
        label {
          display: block;
          margin-bottom: 10px;
        }
        .brand-options-dropdown {
          z-index: 9999;
          height: 300px;
          overflow: hidden;
          overflow-y: scroll;
        }

        .so-button {
          margin-top: 10px;
        }
      }
    }
    .statistics-wrapper {
      padding: 10px;
      height: 800px;
    }
  }
}

.sub-modal-actions {
  button {
    padding: 10px 20px;
    margin-left: 15px;
  }
}
