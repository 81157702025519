@import "../../assets/stylesheets/variables.scss";

.navigation-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  background-image: linear-gradient(to right, #2a516a 0, #0d5e57 88%);
  color: #fff;
  z-index: 999999999999;

  .navigation-container {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0, transparent 30px, transparent 100%);
    display: flex;
    align-items: center;
    width: 100%;

    .logo {
      padding: 10px 20px;
      border-right: 1px solid hsla(0, 0%, 100%, 0.2);
      width: 100px;
      height: 70px;
    }

    .navigation {
      height: $main-navigation-height;
      display: flex;

      .nav-link {
        display: flex;
        flex-direction: column;
        padding: 0px 25px;
        line-height: 60px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border-right: 1px solid hsla(0, 0%, 100%, 0.2);

        &.admin {
          svg {
            transform: rotate(90deg);
          }
        }

        &.active {
          background-color: rgba(rgb(0, 0, 0), 0.4);
        }

        &:hover {
          background-color: rgba(rgb(0, 0, 0), 0.3);

          &.admin {
            svg {
              transform: rotate(90deg) scale(1.3);
            }
          }
          svg {
            transform: scale(1.3);
          }
        }

        transition: all 0.3s;
      }

      .bundles-icon {
        &::before {
          content: "\e900";
        }
      }

      .nav-link-text {
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 7.5px;
        opacity: 0.75;
        display: block;
        line-height: 12px;
        font-family: nimbus-sans, Helvetica, Arial, sans-serif;
      }

      a {
        color: inherit;
      }
    }

    .user-options {
      display: flex;
      align-items: center;
      margin-left: auto;
      .user-avatar {
        width: 40px;
        height: 40px;
        margin-right: 30px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
      }
      .user-settings {
        width: 50;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        &:hover {
          background-color: rgba(rgb(0, 0, 0), 0.3);
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .navigation-wrapper {
    left: 0px;
    transition: all 0.5s;
    &.mobile-closed {
      left: -100px;
    }
    &.mobile-open {
      left: 0px;
    }

    flex-direction: column;
    width: $main-navigation-mobile-width;
    height: 100vh;

    .navigation-container {
      flex-direction: column;

      .logo {
        border-right: 0px;
      }
      .navigation {
        flex-direction: column;
        height: calc(100vh - 190px);
        overflow: auto;

        .nav-link {
          padding: 10px 10px;
          border-right: 0px;

          &.logbook {
            display: none;
          }
        }
      }
      .user-options {
        flex-direction: column;
        width: 100%;

        .user-settings {
          margin-right: 0px;
        }
        .user-avatar {
          margin-right: 0px;
        }
      }
    }
  }
}
