.bundled-stories-table-container {
  width: 1040px;

  font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: initial;
  text-transform: none;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bundled-stories-item {
  background-color: white;
  padding: 15px 20px;
  display: flex;
  margin-top: 1px;

  .datetime {
    width: 50px;
    margin-right: 20px;
    align-self: flex-end;
  }

  .title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-weight: 600;
  }

  .pageviews,
  .engagement {
    width: 100px;
    display: flex;
    justify-content: center;
    position: relative;

    .icon-number {
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      position: absolute;
      left: 30px;
      line-height: 45px;
      width: 40px;
      text-align: center;
      font-size: 14px;
      font-weight: 600px;
    }
  }

  .pageviews {
    .icon-number {
      color: #1abcae;
    }
  }
}
