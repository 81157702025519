@import "../../assets/stylesheets/variables.scss";

.mobile-navigation-wrapper {
  display: none;
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .mobile-navigation-wrapper {
    display: flex;
    align-items: center;
    height: 55px;

    background-color: #4381aa;
    transition: all 0.3s;

    &.menu-shown {
      padding-left: 90px;
    }

    .mobile-menu-page-title {
      padding: 10px 15px;
      font-size: 20px;
      font-weight: 600;
      line-height: 35px;
      color: white;
    }
  }
}
