.profile-photo-container {
  text-align: center;

  h3 {
    font-size: 16px;
    font-weight: 600;
  }
  img {
    margin: auto;
    margin-bottom: 10px;
    display: block;
    max-height: 250px;
    max-width: 250px;
  }

  input {
    visibility: hidden;
  }
  label {
    display: block;
    width: 200px;
    margin: auto;
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    background-color: #1abcae;
    padding: 7px 15px;
    border-radius: 20px;

    cursor: pointer;
    &:hover {
      background-color: #1abcae94;
    }
  }
}
