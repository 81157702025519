@import "../../assets/stylesheets/variables.scss";

@mixin button-style {
  height: 40px;
  color: white;
  border: none;
  border-radius: 20px;
  margin-bottom: 15px;
  font-size: 1.4em;
  font-weight: 400;
  font-family: "Plaax Webfont", "Helvetica", "Arial", sans-serif;
  padding-bottom: 4px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

@function darkenButton($color) {
  @return darken($color, 20%);
}

.smartocto-ai-page {
  .right-container {
    position: relative;
    width: 380px;
    padding-left: 30px;

    h3 {
      font-family: "Plaax Webfont", "Helvetica", "Arial", sans-serif;
      font-size: 1.3em;
      padding-bottom: 10px;
      margin: 0px;
      color: #4a4a4a;
    }
    .headline-buttons {
      display: flex;

      button {
        @include button-style;

        background-color: #e63656;
        width: 48%;

        &:nth-of-type(even) {
          margin-left: 4%;
        }
        &:hover {
          background-color: darkenButton(#e63656);
        }
      }
    }

    .output-buttons {
      margin-bottom: 20px;

      button {
        @include button-style;
        width: 100%;
        background-color: #e63656;
        &:hover {
          background-color: darkenButton(#e63656);
        }
      }
    }
    .output-buttons-userneeds {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      button {
        @include button-style;
        width: 48%;

        &:nth-of-type(even) {
          margin-left: 4%;
        }
      }

      .btn-functional {
        background-color: #00a8f3;
        &:hover {
          background-color: darkenButton(#00a8f3);
        }
      }
      .btn-emotional {
        background-color: #ff0000;
        &:hover {
          background-color: darkenButton(#ff0000);
        }
      }
      .btn-contextual {
        background-color: #940088;
        &:hover {
          background-color: darkenButton(#940088);
        }
      }
      .btn-actionable {
        background-color: #6ac200;
        &:hover {
          background-color: darkenButton(#6ac200);
        }
      }
    }

    .coming-soon-buttons {
      margin-bottom: 20px;

      button {
        @include button-style;
        width: 100%;
        background-color: #c8c8c8;
      }
    }

    .contact-us {
      font-family: "Plaax Webfont", "Helvetica", "Arial", sans-serif;
      font-size: 1em;
      position: absolute;
      bottom: 40px;
      font-weight: 300;
      color: #4a4a4a;
    }
  }
}
