.report-row-ab-tests-container {
  padding: 6px;
  margin: 4px;
  background-color: white;
  * {
    font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
  }

  .title {
    flex: 1;
    font-size: 16px;
    line-height: 20px;
    font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #8d8d8d;
  }
  .lists-container {
    display: flex;
    justify-content: space-between;

    .list-title {
      flex: 1;
      font-size: 6px;
      line-height: 10px;
      font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
      font-weight: 300;
      color: black;
    }
    .authors-list {
      width: 49%;
      .list-item {
        padding: 6px;
        margin-bottom: 4px;
        background: #f4f6fb;
      }
      .author-name {
        font-size: 14px;
        font-weight: 300;
        margin: 0px;
        padding: 0px;
      }
      .number-of-tests {
        font-size: 6px;
        font-weight: 300;
        line-height: 10px;

        span {
          color: #60d470;
        }
      }

      .arrow-up {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        border-bottom: 5px solid #d3d5da;

        display: inline-block;
        transform: scale(0.7);
        margin-left: 2px;
        margin-right: 2px;

        &.green {
          border-bottom: 5px solid #60d470;
        }
      }
    }

    .top-tests-list {
      width: 49%;
      .list-item {
        display: flex;
        padding: 6px;
        margin-bottom: 4px;
        background: #f4f6fb;

        .title {
          font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
          font-size: 8px;
          font-weight: 300;
          line-height: 8px;
        }
        .views {
          font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-weight: 300;
          line-height: 8px;

          span {
            display: block;
            color: #d3d5da;
            font-size: 8px;
            padding-left: 6px;
          }
        }
      }
    }
    .most-increase-list {
    }
  }
}
