$robotoslab-font-name: "Roboto Slab Webfont";
$robotoslab-font-cleanname: "robotoslab";
$robotoslab-font-path: "../../fonts/" + $robotoslab-font-cleanname;
$robotoslab-font-stack: $robotoslab-font-name, "Helvetica", "Arial", sans-serif;

$robotoslab-font-weights: (
  "thin": (
    weight: 100,
    style: normal,
  ),
  "light": (
    weight: 300,
    style: normal,
  ),
  "regular": (
    weight: normal,
    style: normal,
  ),
  "bold": (
    weight: bold,
    style: normal,
  ),
);

@each $weight in map-keys($robotoslab-font-weights) {
  $properties: map-get($robotoslab-font-weights, $weight);
  $font-style: map-get($properties, style);
  $font-weight: map-get($properties, weight);

  @font-face {
    font-family: $robotoslab-font-name;
    font-style: $font-style;
    font-weight: $font-weight;

    src: url($robotoslab-font-path + "/eot/" + $robotoslab-font-cleanname + "-" + $weight + ".webfont.eot");
    src: url($robotoslab-font-path + "/eot/" + $robotoslab-font-cleanname + "-" + $weight + ".webfont.eot?#iefix")
        format("embedded-opentype"),
      url($robotoslab-font-path + "/woff2/" + $robotoslab-font-cleanname + "-" + $weight + ".webfont.woff2")
        format("woff2"),
      url($robotoslab-font-path + "/woff/" + $robotoslab-font-cleanname + "-" + $weight + ".webfont.woff")
        format("woff"),
      url($robotoslab-font-path + "/ttf/" + $robotoslab-font-cleanname + "-" + $weight + ".webfont.ttf")
        format("truetype");
  }

  %font-#{$robotoslab-font-cleanname}-#{$weight} {
    font-family: $robotoslab-font-stack;
    font-style: $font-style;
    font-weight: $font-weight;
  }
}

@mixin font-robotoslab(
  $font-size: 16px,
  $line-height: 20px,
  $font-style: normal,
  $text-transform: none,
  $letter-spacing: initial,
  $font-weight: normal
) {
  font-family: $robotoslab-font-stack;
  font-size: $font-size;
  font-style: $font-style;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-transform: $text-transform;
}

@mixin font-robotoslab-thin($values...) {
  @include font-robotoslab($font-weight: 100, $values...);
}

@mixin font-robotoslab-light($values...) {
  @include font-robotoslab($font-weight: 300, $values...);
}

@mixin font-robotoslab-regular($values...) {
  @include font-robotoslab($font-weight: normal, $values...);
}

@mixin font-robotoslab-bold($values...) {
  @include font-robotoslab($font-weight: bold, $values...);
}
