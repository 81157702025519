@import "../assets/stylesheets/variables.scss";

.settings-page-container {
  padding-top: 70px;
  color: white;
  background-image: linear-gradient(to bottom, #3b7194 0, #12847a 88%);
  min-height: 100vh;
  font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;

  h1 {
    font-size: 20px;
    background-color: #4381aa;
    margin: 1px;
    margin-top: 0px;
    padding: 15px;

    button {
      float: right;
    }
  }

  h3 {
    font-size: 16px;
  }

  .settings-page-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .settings-column {
    flex: 1 0 25%;
    padding: 10px 40px;

    .profile-page-container {
      display: flex;
      justify-content: center;
    }

    .pass-strength {
      height: 10px;
    }

    .input-container {
      margin-bottom: 10px;
    }

    .checkbox-container {
      margin-top: 10px;
      font-size: 12px;

      input {
        position: relative;
        top: 3px;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1260px) {
  .settings-page-content {
    .settings-column {
      flex: 1 0 50%;
    }
  }

  .profile-notifications-wrapper {
    flex-wrap: wrap;
    .profile-notification-container {
      width: 48%;
    }
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .settings-page-container {
    padding-top: 0px;
    &.mobile-menu-shown {
      padding-left: $main-navigation-mobile-width;
    }
    .settings-page-content {
      flex-wrap: wrap;
      flex: 1;
      .settings-column {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .settings-page-container {
    .settings-page-content {
      flex-wrap: wrap;
      display: block;
      flex: 1;
      .settings-column {
        flex-grow: 1;
        width: 100%;
        padding: 12px;
      }
    }
  }

  .profile-notifications-wrapper {
    flex-wrap: wrap;
    padding: 2px;
  }
}
