.loading-page-container {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to right, #2a516a 0, #0d5e57 88%);
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-container {
    width: 120px;
  }
  .please-wait-message {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: "Source Sans Pro Webfont";
      color: white;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .dots-loader-container {
      margin-left: 5px;
    }
  }

  .loading-message {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dots-loader-container {
      margin-right: 5px;
    }

    span {
      font-family: "Source Sans Pro Webfont";
      color: white;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
