@import "../../assets/stylesheets/variables.scss";

.bundles-loader-container {
  min-height: calc(100vh - ($main-navigation-height + 100px));
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bundle-container {
  min-height: 100vh;
  background-color: $page-background-color;

  header {
    padding: 15px 15px 20px 15px;

    .bundle-count {
      font-family: "Source Sans Pro Webfont";
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      padding: 0px;
      margin: 0px;
    }

    h1 {
      font-family: "Roboto Slab Webfont";
      font-size: 30px;
      line-height: 34px;
      color: #4a4a4a;
    }

    button {
      font-family: "Source Sans Pro Webfont";
      font-size: 14px;
      font-weight: 300;
      line-height: 25px;
      background-color: #fff;
      border: 0px;
      border-radius: 20px;
      padding: 0 10px 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25px;
      color: rgba(74, 74, 74, 0.9);
      transition: 0.15s ease-out, color 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;

      .bundle-del-icon {
        display: flex;
        align-items: center;
        height: 25px;
        margin-left: 6px;
        padding-left: 6px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);

        svg {
          fill: rgba(74, 74, 74, 0.9);
          width: 10px;
          height: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #979797;
        color: #fff;
      }
    }
  }

  h2 {
    font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: initial;
    text-transform: none;
  }

  .catch {
    padding: 0px 15px;

    .pageviews-graph-container {
      position: relative;

      .bundle-viewby-selector {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;

        .switch-button {
          padding: 10px 15px;
          color: #4a4a4a;
          border-radius: 20px;
          font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: initial;
          text-transform: none;
          border-radius: 40px;
          cursor: pointer;
          display: inline-block;
          margin-left: 10px;

          background-color: #e6e6e6;

          &.story.active {
            background-color: #1cbbaf;
            color: white;
          }

          &.origin.active {
            background-color: #ee1999;
            color: white;
          }

          &.not-active {
            &:hover {
              background-color: #979797;
              color: white;
            }
          }
        }
      }
      padding: 20px;
      width: 1040px;
      height: 450px;
      background-color: white;
      margin-bottom: 50px;
      h3 {
        margin: 0px;
        margin-bottom: 20px;
      }

      .loader-container {
        width: 100%;
        height: 300px;
      }

      .last-story-info {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        font-family: "Source Sans Pro Webfont";
      }
    }
  }

  .bundled-stories {
    padding: 0px 15px;

    .bundled-stories-table-title {
      display: flex;
      width: 1040px;
      justify-content: space-between;

      .bundles-table-labels-container {
        display: flex;
        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 14px;

        > div {
          width: 100px;
          padding: 15px;
        }
      }
    }
  }
}
