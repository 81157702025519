.ab-tests-wrapper {
  background-color: #f3f1ed;
  min-height: 100vh;
  padding-left: 15px;
  padding-right: 15px;

  h1 {
    font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: initial;
    text-transform: none;
    color: #4a4a4a;
    margin-bottom: 5px;
  }

  .ab-test-results-general {
    font-family: "Source Sans Pro Webfont";
    font-size: 16px;
    font-weight: 300;
    color: #4a4a4a;
  }
}

.ab-test-container {
  background-color: white;
  padding: 15px;
  margin-bottom: 15px;
  font-family: "Source Sans Pro Webfont";
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 300;

  .ab-test-creation-date {
    font-family: "Source Sans Pro Webfont";
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    color: #4a4a4a;
    margin: 0px;
  }

  .title-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: #444;
    }
  }

  .ab-test-title {
    font-family: "Source Sans Pro Webfont";
    font-weight: 600;
    font-size: 20px;
    color: #000;
    line-height: 25px;
    margin: 0px;
    margin-bottom: 10px;
  }
}

.ab-test-header-icon:before {
  font-family: "icomoon";
  content: "\e94e";
  font-size: 30px;
  color: #e6e6e6;
  padding-right: 5px;
}

.ab-test-stats {
  display: inline-block;
  margin-bottom: 20px;
  .details {
    display: flex;

    .detail-bold {
      font-weight: bold;
    }

    div {
      margin-right: 10px;
      width: 170px;

      &:last-of-type {
        width: auto;
      }
    }
  }
}

.ab-test-data {
  display: flex;

  .ab-test-data-table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 0px;
    border-right: 1px solid #444;
    width: 100%;
    height: 0px;

    thead,
    td,
    tr,
    th {
      background-color: white;
      border: none;
      border-bottom: 0px !important;
      padding: 0px;
      height: 5px !important;
      margin: 0px;
      color: black !important;
    }
    .option-letter {
      width: 3% !important;
      font-size: 16pt;
      text-align: center;
    }
    .option-title,
    .option-image {
      width: 35% !important;
      padding-left: 20px;
      padding-right: 20px;

      img {
        vertical-align: middle;
        margin-bottom: 5px;
        margin-right: 10px;
      }
      span {
        vertical-align: middle;
      }
    }
    .option-image img {
      width: 100px;
    }

    .option-views {
      width: 5% !important;
      text-align: left;
      font-weight: bold;
    }
    td.option-ctr {
      width: 10% !important;
      color: #1cbdaf !important;
      text-align: center;
    }
    td.option-ltr {
      width: 10% !important;
      color: #1cbdaf !important;
      text-align: center;
    }
    td.option-loyalty {
      width: 15% !important;
      padding-left: 10px;
      padding-right: 20px;
    }

    .progress-bar {
      margin-top: 3px;
      background-color: #ccc;
      border-radius: 2px;

      width: 100% !important;
      height: 20px;

      position: relative;
      display: block;
      margin-bottom: 5px;
    }

    .progress-bar > span {
      background-color: #999;
      border-radius: 2px;
      display: block;
      font-weight: bold;
      font-size: 14px;
    }

    .test-won {
      td {
        background-color: #1cbdaf;
        color: white !important;
      }
    }
  }

  .ab-test-winning-results {
    width: 400px;
    padding-left: 20px;

    .ab-test-overwrite-switch {
      margin-top: 15px;
    }

    .detail-bold {
      font-weight: bold;
    }
  }
}

.ab-test-explanation {
  margin-top: 10px;
  font-style: italic;
}

.ab-tests-loader-container {
  width: 100%;
  height: 70vh;
  text-align: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
