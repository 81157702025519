.tentacles-settings-modal-wrapper {
  width: 1500px;
  height: auto;
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  height: calc(100vh - 140px);
  overflow-y: auto;

  .tentacles-settings-container {
    display: flex;
    > div {
      width: 22%;
      margin: 0px 1.5%;
    }
    margin-bottom: 30px;

    .switch-container {
      padding: 10px 0px;
      position: relative;
      top: -10px;

      .switch-label {
        margin-left: 10px;
        position: relative;
        top: 2px;
      }
    }

    .inputs-container {
      height: 60vh;
      padding-right: 15px;
      overflow-x: hidden;
      overflow-y: scroll;

      .text-area-wrapper {
        margin-bottom: 5px;
        p {
          margin-top: 1px !important;
        }
      }
    }
  }
}
