@import "../../assets/stylesheets/variables.scss";

.numbers-container {
  > h3 {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: initial;
    text-transform: none;
    -webkit-transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    -moz-transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    transition: color 0.25s, border-color 0.5s, color 0.25s, border-color 0.5s;
    border-bottom: 1px solid #979797;
    margin: 20px 0px;
    padding-bottom: 10px;
    display: block;
  }
}

.numbers-sections-container {
  display: flex;
  margin-top: 10px;

  .numbers-section {
    background-color: white;
    margin-right: 15px;
    padding: 20px;

    .numbers-header {
      height: 150px;

      h3 {
        text-align: center;
        color: #1abcae;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 300;
        margin: 0px;
      }
      .numbers-details-container {
        display: flex;
      }
      .numbers-details {
        flex: 1;
        text-align: center;
      }
    }

    .numbers-content {
      .numbers-details-container {
        display: flex;
      }
      .numbers-details {
        flex: 1;
        text-align: center;
      }
      .numbers-details-half-w {
        width: 50%;
        text-align: center;
      }
    }

    .numbers-header-tooltip {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .web-numbers {
    width: 250px;
  }

  .app-numbers {
    width: 250px;
  }

  .facebook-numbers {
    width: 400px;
  }

  .separator {
    margin-top: 20px;
    border: 2.5px solid #f3f1ed;
  }

  h4 {
    font-weight: 600px;
    color: #4a4a4a;
  }

  .numbers-details-title {
    font-size: 18px;
    font-weight: 300;
    color: #4a4a4a;
    line-height: 24px;
  }

  .numbers-details-value {
    font-size: 18px;
    font-weight: 500;
    color: #4a4a4a;
    line-height: 23px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .facebook-reactions-container {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .facebook-reaction-icon {
      width: 50px;
      text-align: center;
    }
    .facebook-reaction-count {
      text-align: center;
    }
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .numbers-sections-container {
    flex-direction: column;

    .web-numbers {
      width: 100%;
      margin-bottom: 15px;
    }

    .app-numbers {
      width: 100%;
      margin-bottom: 15px;
    }

    .facebook-numbers {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
