.tooltip-element {
  position: relative;
  cursor: pointer;
  .tooltip-message {
    position: absolute;
    z-index: 999;
    top: 30px;
    left: -135px;
    width: 300px;
    background-color: #555;
    color: white;
    padding: 5px;
    text-align: center;
  }

  &.tooltip-top {
    .tooltip-message {
      top: -60px;
    }
  }

  &.tooltip-right {
    .tooltip-message {
      top: 0;
      left: 30px;
    }
  }

  &.tooltip-left {
    .tooltip-message {
      top: 0;
      left: -315px;
    }
  }
}
