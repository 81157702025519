.brand-selector-container {
  //   border: 1px solid red;
  width: 100%;

  ul {
    padding: 0px;
    margin-top: 0px;
    padding-top: 0px;
    list-style-type: none;
    height: 400px;
    overflow-y: scroll;
    margin-bottom: 0px;
    background-color: rgba(0, 0, 0, 0.2);

    a {
      text-decoration: none;
      color: inherit;
    }

    li {
      border-bottom: 1px solid rgb(32, 32, 32);
      padding: 7px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
