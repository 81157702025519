@import "../../assets/stylesheets/variables.scss";

.side-nav-container {
  position: fixed;
  background-image: linear-gradient(to bottom, #3b7194 0, #12847a 88%);
  width: 280px;
  top: $main-navigation-height;
  height: calc(100vh - $main-navigation-height);
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .so-navigation-filters-wrapper {
    background-color: #387292;
    width: 280px;
    z-index: 9999;
  }

  .navigation-items-container {
    height: calc(100vh - 130px);
    overflow-y: scroll;

    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
      display: none;  
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .mobile-menu-icon {
    display: none;
  }
}

@media screen and (max-width: 770px) {
  .side-nav-container {
    transition: all 0.5s;
    background-color: #4381aa !important;
    height: 100vh;
    overflow: visible;
    &.mobile-closed {
      left: -100%;
    }
    &.mobile-open {
      left: $main-navigation-mobile-width;
      width: calc(100% - $main-navigation-mobile-width);

      .so-navigation-filters-wrapper {
        background-color: #4381aa !important;
        left: $main-navigation-mobile-width;
        width: calc(100% - $main-navigation-mobile-width);
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      }
    }
    padding-top: 30px;
    top: 0px;
    left: $main-navigation-mobile-width;
    z-index: 3;
    width: 100%;

    .navigation-items-container {
      margin-top: 0px;
      padding-top: 30px;
      height: 100vh;
      overflow-y: scroll;
    }

    .so-navigation-filters-wrapper {
      background-color: rgba(67, 127, 168, 0.973) !important;
      background-image: -webkit-linear-gradient(#4381aa, #4381aa);
      background-repeat: repeat;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 9999999;
      display: flex;
      height: auto;
      transition: all 0.5s;
    }

    .mobile-menu-icon {
      background-color: #4381aa;
      width: 60px;
      display: block;
      padding: 6px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .close-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding-top: 5px;
      }
    }
  }
}
