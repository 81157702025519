.add-item-to-bundle-modal-container {
  margin-top: 100px;

  background-color: white;

  .bundle-input-header {
    padding: 20px;

    h2 {
      font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
      font-weight: 400;
    }
  }

  .available-bundles-container {
    .title {
      background-color: gainsboro;
      padding: 5px 20px;
    }
    .bundle-to-select {
      padding: 10px 20px;

      span {
        float: right;
        width: 30px;
        height: 20px;
        border-radius: 20px;
        background-color: gainsboro;
        text-align: center;
      }

      &:hover {
        background-color: gray;
        color: white;
        cursor: pointer;

        span {
          color: black;
        }
      }
    }
  }
}
