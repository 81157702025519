$color-green: rgb(26, 188, 174); // 1ABBAE
$color-darkgrey: rgb(74, 74, 74); // 4A4A4A

.stream-table-ink-container {
  display: flex;
  float: right;
  align-content: center;
  justify-content: right;
  width: 155px;

  .stream-table-ink {
    cursor: pointer;
    margin-top: 4px;
    transition: color 200ms;

    svg {
      fill: rgba(#000, 0.2);
      font-size: 13px;
      vertical-align: top;
      transform: rotateX(180deg);
    }

    &:hover {
      svg {
        fill: rgba(#000, 0.4);
      }
    }

    &.is-inked {
      svg {
        fill: $color-green;
      }
    }
  }

  .stream-table-ink-status {
    position: relative;
    width: calc(100% - 51px);
    text-align: right;
    margin-right: 8px;

    &:hover,
    &:focus {
      .stream-table-ink-inkers {
        opacity: 1;
        right: 80%;
      }
    }

    .stream-table-ink-status-label {
      max-width: 90px;
      text-align: right;

      strong {
        font-style: normal;
        font-weight: bold;
        white-space: nowrap;
      }

      em {
        font-style: italic;
        white-space: nowrap;
      }
    }

    .stream-table-ink-inkers {
      // @include transition(opacity $easing-change, right $easing-change);

      background-color: rgba($color-darkgrey, 0.95);
      border-radius: 3px;
      color: #fff;
      float: left;
      font-weight: 600;
      min-width: 100%;
      opacity: 0;
      padding: 15px;
      pointer-events: none;
      position: absolute;
      text-align: left;
      right: 50%;
      top: -10px;
      transition: opacity 0.15s ease-out, right 0.15s ease-out;

      &::before {
        border: 10px solid transparent;
        border-left-color: rgba($color-darkgrey, 0.95);
        content: "";
        display: block;
        height: 0;
        position: absolute;
        right: -20px;
        top: 26px;
        width: 0;
      }

      li {
        height: 20px;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .stream-table-ink-container {
    width: unset;

    .stream-table-ink {
      padding: 3px 30px 0 0;
    }
  }
}
