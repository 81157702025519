$plaax-font-name: "Plaax Webfont";
$plaax-font-cleanname: "plaax";
$plaax-font-path: "../../fonts/" + $plaax-font-cleanname;
$plaax-font-stack: $plaax-font-name, "Helvetica", "Arial", sans-serif;

$plaax-font-weights: (
  "light": (
    weight: 300,
    style: normal,
  ),
  "regular": (
    weight: normal,
    style: normal,
  ),
  "bold": (
    weight: bold,
    style: normal,
  ),
  "heavy": (
    weight: bolder,
    style: normal,
  ),
);

@each $weight in map-keys($plaax-font-weights) {
  $properties: map-get($plaax-font-weights, $weight);
  $font-style: map-get($properties, style);
  $font-weight: map-get($properties, weight);

  @font-face {
    font-family: $plaax-font-name;
    font-style: $font-style;
    font-weight: $font-weight;

    src: url($plaax-font-path + "/woff2/" + $plaax-font-cleanname + "-" + $weight + ".webfont.woff2") format("woff2"),
      url($plaax-font-path + "/woff/" + $plaax-font-cleanname + "-" + $weight + ".webfont.woff") format("woff");
  }

  %font-#{$plaax-font-cleanname}-#{$weight} {
    font-family: $plaax-font-stack;
    font-style: $font-style;
    font-weight: $font-weight;
  }
}

@mixin font-plaax(
  $font-size: 16px,
  $line-height: 20px,
  $font-style: normal,
  $text-transform: none,
  $letter-spacing: initial,
  $font-weight: normal
) {
  font-family: $plaax-font-stack;
  font-size: $font-size;
  font-style: $font-style;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-transform: $text-transform;
}

@mixin font-plaax-light($values...) {
  @include font-plaax($font-weight: 300, $values...);
}

@mixin font-plaax-regular($values...) {
  @include font-plaax($font-weight: normal, $values...);
}

@mixin font-plaax-bold($values...) {
  @include font-plaax($font-weight: bold, $values...);
}

@mixin font-plaax-bolder($values...) {
  @include font-plaax($font-weight: bolder, $values...);
}
