.report-row-with-list-container {
  padding: 6px;
  margin: 4px;
  background-color: white;

  .subject-title {
    flex: 1;
    font-size: 16px;
    line-height: 20px;
    font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #8d8d8d;
  }

  .report-list-title {
    flex: 1;
    font-size: 6px;
    line-height: 10px;
    font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: black;
  }

  .report-row-list {
    .list-item {
      padding: 6px;
      margin-bottom: 4px;
      background: #f4f6fb;

      .indicators {
        font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
        font-size: 6px;
        font-weight: 300;
        line-height: 10px;

        .arrow-up {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;

          border-bottom: 5px solid #d3d5da;

          display: inline-block;
          transform: scale(0.7);

          &.green {
            border-bottom: 5px solid #60d470;
          }
        }

        .dot {
          width: 4px;
          height: 4px;
          border: 2px solid #60d470;
          border-radius: 10px;
          display: inline-block;
          margin-right: 4px;

          &.green {
            background-color: #60d470;
          }
        }

        span {
          &.green {
            color: #60d470;
          }
          &.gray {
            color: #b7b7b7;
          }
        }
      }

      .text {
        color: #454545;
        font-size: 8px;
        line-height: 8px;
        font-family: "Plaax Webfont", Helvetica, Arial, sans-serif;
        font-weight: 300;
      }
    }
  }
}
