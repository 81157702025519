.profile-settings {
  .profile-details-save-changes {
    height: 50px;
  }

  .new-password-container {
    margin-bottom: 10px;
  }

  input.error {
    border: 2px solid darkred;
    background-color: rgb(255 243 243);
  }

  .password-progress-bar-container {
    position: relative;
    height: 25px;
    padding: 3px 10px;
    width: 100%;

    .password-progress-bar {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 23px;
      transition: width 0.3s;

      &.password-strength-1 {
        background-color: #f70;
        width: 25%;
      }
      &.password-strength-2 {
        background-color: #ff0;
        width: 50%;
      }
      &.password-strength-3 {
        background-color: #aeff00;
        width: 75%;
      }
      &.password-strength-4 {
        background-color: #0f0;
        width: 100%;
      }
    }

    .password-strength-message {
      position: relative;
      z-index: 2;
      color: black;
      font-size: 12px;
    }
  }

  .passwords-matching-message {
    color: darkred;
    background-color: white;
    display: inline-block;
    padding: 5px;
  }
}
