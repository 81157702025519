@import "../../../assets/stylesheets/variables.scss";

.stacked-area-graph-legend-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  .legend-item {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .legend-icon {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-right: 7px;
  }
  .legend-label {
    font-family: "Source Sans Pro Webfont";
    color: $color-gray;
    font-size: 14px;
    font-weight: 300;
  }
}
