.question-mark-icon {
  background: #1abcae;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  height: 16px;
  line-height: 14px;
  text-align: center;
  width: 16px;
}
