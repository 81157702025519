$sourcesanspro-font-name: "Source Sans Pro Webfont";
$sourcesanspro-font-cleanname: "sourcesanspro";
$sourcesanspro-font-path: "../../fonts/" + $sourcesanspro-font-cleanname;
$sourcesanspro-font-stack: $sourcesanspro-font-name, "Helvetica", "Arial", sans-serif;

$sourcesanspro-font-weights: (
  "extralight": (
    weight: 200,
    style: normal,
  ),
  "extralightitalic": (
    weight: 200,
    style: italic,
  ),
  "light": (
    weight: 300,
    style: normal,
  ),
  "lightitalic": (
    weight: 300,
    style: italic,
  ),
  "regular": (
    weight: normal,
    style: normal,
  ),
  "regularitalic": (
    weight: normal,
    style: italic,
  ),
  "semibold": (
    weight: 600,
    style: normal,
  ),
  "semibolditalic": (
    weight: 600,
    style: italic,
  ),
  "bold": (
    weight: bold,
    style: normal,
  ),
  "bolditalic": (
    weight: bold,
    style: italic,
  ),
  "black": (
    weight: 900,
    style: normal,
  ),
  "blackitalic": (
    weight: 900,
    style: italic,
  ),
);

@each $weight in map-keys($sourcesanspro-font-weights) {
  $properties: map-get($sourcesanspro-font-weights, $weight);
  $font-style: map-get($properties, style);
  $font-weight: map-get($properties, weight);

  @font-face {
    font-family: $sourcesanspro-font-name;
    font-style: $font-style;
    font-weight: $font-weight;

    src: url($sourcesanspro-font-path + "/eot/" + $sourcesanspro-font-cleanname + "-" + $weight + ".webfont.eot");
    src: url($sourcesanspro-font-path + "/eot/" + $sourcesanspro-font-cleanname + "-" + $weight + ".webfont.eot?#iefix")
        format("embedded-opentype"),
      url($sourcesanspro-font-path + "/woff2/" + $sourcesanspro-font-cleanname + "-" + $weight + ".webfont.woff2")
        format("woff2"),
      url($sourcesanspro-font-path + "/woff/" + $sourcesanspro-font-cleanname + "-" + $weight + ".webfont.woff")
        format("woff"),
      url($sourcesanspro-font-path + "/ttf/" + $sourcesanspro-font-cleanname + "-" + $weight + ".webfont.ttf")
        format("truetype");
  }

  %font-#{$sourcesanspro-font-cleanname}-#{$weight} {
    font-family: $sourcesanspro-font-stack;
    font-style: $font-style;
    font-weight: $font-weight;
  }
}

@mixin font-sourcesanspro(
  $font-size: 16px,
  $line-height: 20px,
  $font-style: normal,
  $text-transform: none,
  $letter-spacing: initial,
  $font-weight: normal
) {
  font-family: $sourcesanspro-font-stack;
  font-size: $font-size;
  font-style: $font-style;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-transform: $text-transform;
  // seems to mess up fonts in safari, doesn't seem to affect other things when turned off
  //-webkit-font-smoothing: subpixel-antialiased;
}

@mixin font-sourcesanspro-extralight($values...) {
  @include font-sourcesanspro($font-weight: 200, $values...);
}

@mixin font-sourcesanspro-light($values...) {
  @include font-sourcesanspro($font-weight: 300, $values...);
}

@mixin font-sourcesanspro-regular($values...) {
  @include font-sourcesanspro($font-weight: normal, $values...);
}

@mixin font-sourcesanspro-semibold($values...) {
  @include font-sourcesanspro($font-weight: 600, $values...);
}

@mixin font-sourcesanspro-bold($values...) {
  @include font-sourcesanspro($font-weight: bold, $values...);
}

@mixin font-sourcesanspro-black($values...) {
  @include font-sourcesanspro($font-weight: 900, $values...);
}
