@import "../assets/stylesheets/variables.scss";

.smartocto-ai-wrapper {
  padding: 30px;
  padding-top: calc($main-navigation-height + 10px);
  padding-bottom: 0px;
  width: 100%;
  background-color: #eeede7;

  &.loading {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .animated-logo-container {
      position: relative;
      min-width: 500px;

      .logo-cover-fix-left {
        position: absolute;
        height: 3px;
        width: 188px;
        background-color: #eeede7;
        top: -5;
        left: 0;
      }
      .logo-cover-fix-right {
        position: absolute;
        height: 3px;
        width: 300px;
        background-color: #eeede7;
        top: -5;
        right: 0;
      }

      img {
        width: 100%;
      }
    }
  }

  .header {
    width: 1250px;
    display: flex;

    .logo {
      width: 400px;
      height: 200px;
    }

    .subtitle {
      flex-grow: 1;
      p {
        text-align: right;
        padding-top: 2em;
        color: #e63656;
        font-size: 2em;
        font-weight: 400;
        font-family: "Plaax Webfont", "Helvetica", "Arial", sans-serif;
        padding-right: 5px;

        .subtitle-bold {
          font-weight: 600;
        }
      }
    }
  }
  .main-wrapper {
    display: flex;
    width: 1260px;

    .left-container {
      width: 900px;

      h3 {
        font-family: "Plaax Webfont", "Helvetica", "Arial", sans-serif;
        font-size: 1.3em;
        padding-bottom: 10px;
        margin: 0px;
        color: #4a4a4a;
      }

      textarea {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        border-bottom-right-radius: 0px;
        margin-bottom: 20px;
        padding: 20px;
        font-size: 18px;
        font-weight: 400;
        resize: vertical;

        &.headline-error-state {
          border: 2px solid #e63656;
        }

        &.article-error-state {
          border: 2px solid #e63656;
        }

        &.headline-textarea {
          height: 100px;
          font-family: "Plaax Webfont", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          font-size: 1.3em;
          line-height: 30px;

          &::placeholder {
            color: #464646;
            font-size: 16px !important;
            font-style: italic;
            font-weight: 300 !important;
            font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
          }

          &::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: #464646;
            font-size: 16px !important;
            font-style: italic;
            font-weight: 300 !important;
            font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
          }
        }

        &.article-textarea {
          height: 300px;
          font-size: 1.1em;
          line-height: 25px;
          font-family: "Source Sans Pro Webfont";

          &::placeholder {
            color: #464646;
            font-size: 16px !important;
            font-style: italic;
            font-weight: 300 !important;
            font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
          }

          &::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: #464646;
            font-size: 16px !important;
            font-style: italic;
            font-weight: 300 !important;
            font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
          }
        }

        &.output-textarea {
          height: 400px;
        }
      }

      .headline-error,
      .article-error {
        color: #e63656;
        position: relative;
        top: -15px;
        font-size: 22px;
        font-style: italic;
        font-weight: 400;
      }

      .output-field {
        width: 100%;
        min-height: 400px;
        background-color: white;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 20px;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;

        &.output-error {
          border: 2px solid #e63656;
        }

        .output-error-text::after {
          //   @include font-awesome;
          font-family: "font awesome";
          content: "\f071";
          color: red;
          font-size: 20px;
        }

        .error {
          background-color: darkred !important;
          padding: 10px;
          color: white !important;
          margin: 10px;
        }

        .headline-suggestions {
          font-size: 1.1em;
          p {
            padding: 5px 0px;
          }
        }

        .response-paragraph {
          padding-top: 20px;

          &:first-of-type {
            padding-top: 5px;
          }
        }

        .sentiment {
          .response-title {
            margin-bottom: 15px;
          }
          li {
            margin-bottom: 5px;
          }
          .horizontal-line {
            width: 100%;
            margin-top: 8px;
            border-bottom: 1px solid rgb(126, 119, 119);
          }
          .legend {
            font-size: 18px;
          }
        }
      }
    }
  }

  .bottom-illustration {
    width: 1250px;
    display: flex;
    justify-content: flex-end;

    .illustration-container {
      width: 700px;
      height: 100px;
      overflow: hidden;
    }
  }
}
