@import "../../../assets/stylesheets/variables.scss";

.impact-radar-page-wrapper {
  background-color: #f3f1ed;
  min-height: 100vh;
}

.impact-radar-page {
  padding-top: 100px;
  padding-left: 30px;

  min-height: 90vh;
  padding-bottom: 50px;

  h1 {
    font-family: $robotoslab-font-stack;
    color: $color-gray;
    font-size: 30px;
    font-weight: normal;
    margin: 0px;
    margin-bottom: 10px;
  }

  .impact-radar-options {
    display: flex;
    flex-wrap: wrap;

    .week-picker-container {
      margin-right: 30px;
    }

    .week-picker-title {
      font-family: $sourcesanspro-font-stack;
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 16px;
      color: $color-gray;
      font-weight: 700;
    }

    .channels-tittle {
      font-family: $sourcesanspro-font-stack;
      margin-top: 10px;
      margin-bottom: 6px;
      font-size: 16px;
      font-size: 16px;
      color: $color-gray;
      font-weight: 700;
    }
  }

  .channels-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .channel-name {
      padding: 0px;
      padding-right: 20px;
      padding-top: 10px;

      text-transform: capitalize !important;

      font-weight: 300;
      font-family: $sourcesanspro-font-stack;
      cursor: pointer;

      &:first-of-type {
        padding-left: 0px;
      }

      &:hover {
        text-decoration: underline;
      }

      &.active {
        text-decoration: underline;
        text-underline-offset: 2px;
        font-weight: 500;
      }
    }

    .channel-indicator-icon {
      display: inline-block;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      border-radius: 15px;
      background-color: red;
      position: relative;
      top: 1px;
    }
    .channel-base-color-facebook {
      background-color: #ff8428;
    }
    .channel-base-color-linkedin {
      background-color: #af00ff;
    }
    .channel-base-color-mobile {
      background-color: #ffcd28;
    }
    .channel-base-color-newsletter {
      background-color: #5bd66b;
    }
    .channel-base-color-twitter {
      background-color: #6a13fe;
    }
    .channel-base-color-youtube {
      background-color: #d8020f;
    }
    .channel-base-color-video {
      background-color: #0c6c9e;
    }
    .channel-base-color-all {
      background-color: $color-gray;
    }
  }

  .impact-radar-filtered-title {
    font-family: $sourcesanspro-font-stack;
    font-size: 18px;
    font-weight: 300;
    color: $color-gray;
    margin-top: 5px;
    margin-bottom: 5px;

    span {
      font-weight: 800;
      text-transform: capitalize;
    }
  }

  .impact-radar-chart-container {
    // height: 750px;
    padding-bottom: 40px;
  }

  .color-facebook-effort {
    fill: rgb(245, 166, 35);
    background-color: rgb(245, 166, 35);
  }
  .color-facebook-impact {
    fill: rgb(211, 71, 5);
    background-color: rgb(211, 71, 5);
  }

  .color-linkedin-effort {
    fill: rgb(189, 16, 224);
    background-color: rgb(189, 16, 224);
  }
  .color-linkedin-impact {
    fill: rgb(94, 0, 137);
    background-color: rgb(94, 0, 137);
  }

  .color-mobile-effort {
    fill: rgb(254, 204, 62);
    background-color: rgb(254, 204, 62);
  }
  .color-mobile-impact {
    fill: rgb(207, 158, 0);
    background-color: rgb(207, 158, 0);
  }

  .color-newsletter-effort {
    fill: rgb(126, 211, 33);
    background-color: rgb(126, 211, 33);
  }
  .color-newsletter-impact {
    fill: rgb(42, 77, 1);
    background-color: rgb(42, 77, 1);
  }

  .color-twitter-effort {
    fill: rgb(74, 144, 255);
    background-color: rgb(74, 144, 255);
  }
  .color-twitter-impact {
    fill: rgb(94, 0, 137);
    background-color: rgb(94, 0, 137);
  }

  .color-youtube-effort {
    fill: rgb(255, 23, 52);
    background-color: rgb(255, 23, 52);
  }
  .color-youtube-impact {
    fill: rgb(163, 0, 0);
    background-color: rgb(163, 0, 0);
  }

  .color-video-effort {
    fill: rgb(132, 196, 228);
    background-color: rgb(132, 196, 228);
  }
  .color-video-impact {
    fill: rgb(11, 107, 157);
    background-color: rgb(11, 107, 157);
  }

  .color-total-effort {
    fill: #1abcae;
    background-color: #1abcae;
  }

  .color-total-impact {
    fill: #4381a6;
    background-color: #4381a6;
  }

  .impact-radar-legend {
    padding: 0px;
    padding-right: 20px;
    padding-top: 10px;

    font-weight: 300;
    font-family: $sourcesanspro-font-stack;

    span {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      position: relative;
      top: 2px;
      border-radius: 20px;
    }
  }

  .y-axis-label {
    font-family: $sourcesanspro-font-stack;
    font-size: 16px;
    font-weight: 300;
  }

  .x-axis {
    text {
      font-family: $sourcesanspro-font-stack;
      font-size: 16px;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .impact-radar-page {
    padding-top: 10px;
    padding-left: 10px;
  }
}
