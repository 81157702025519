$default-color: "#fff";
$success-color: rgb(26, 188, 174); // #1abcae
$danger-color: rgb(177, 30, 30); // #b11e1e
$warning-color: rgb(255, 167, 38); // #ffa726

$dark-text-color: rgb(17, 17, 17);
$light-text-color: rgb(247, 247, 247);

@function darken($color) {
  @return mix(black, $color, 20%);
}

.so-button {
  border-width: 0px;
  font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  &.full-width {
    width: 100%;
  }

  * {
    vertical-align: middle;
  }
}

.so-button-circle {
  border-width: 0px;
  cursor: pointer;
  border-radius: 50%;
}

.so-button--default {
  background-color: #fff;
  color: #0c5d56;
}

.so-button--success {
  background-color: $success-color;
  color: white;
  border-radius: 16px;

  &:hover {
    background-color: darken($success-color);
  }
}

.so-button--danger {
  background-color: $danger-color;
  color: $light-text-color;

  &:hover {
    background-color: darken($danger-color);
  }
}

.so-button--warning {
  background-color: $warning-color;
  color: $dark-text-color;

  &:hover {
    background-color: darken($warning-color);
  }
}

.so-button--small {
  padding: 5px;
}
.so-button--medium {
  padding: 6px 15px;
}
.so-button--large {
  padding: 20px;
}
.so-button-rounded {
  border-radius: 30px;
}
.so-button-fullwidth {
  width: 100%;
}

.so-button {
  &:disabled {
    background-color: gray;
  }
}
