@import "../../assets/stylesheets/variables.scss";

.dev-tools-wrapper {
  position: relative;
  user-select: none;
  background-color: #54a1d4;
  height: 70px;
  margin-right: 20px;
  padding-top: 15px;

  .dev-tools-icon {
    cursor: pointer;
  }

  .dev-tools-dropmenu-container {
    position: absolute;
    z-index: 99;
    background-color: #54a1d4;
    width: 400px;
    height: 200px;
    right: 0;
    top: 70px;
    padding: 0px 15px;
    padding-bottom: 20px;

    .dev-tools-selector-current-value {
      font-size: 18px;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between;

      .value {
        padding: 10px;
        height: 40px;
      }

      .icons {
        display: flex;
      }

      .drop-icon {
        font-size: 20px;
        width: 40px;
        height: 40px;
        text-align: center;
        vertical-align: middle;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }

        div {
          transform: rotate(270deg);
          padding: 5px;
          padding-top: 12px;
          text-align: center;

          &.active {
            transform: rotate(90deg);
          }

          transition: transform 0.3s;
        }
      }

      .save-icon {
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .api-options-container,
    .dev-tools-options-container {
      position: absolute;
      top: 30;
      background-color: #54a1d4;
      width: 330px;
      z-index: 100;

      .api-option {
        padding: 7px 10px;
        background-color: rgba(0, 0, 0, 0.4);
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .dev-tools-wrapper {
    position: absolute;
    bottom: 10px;
    left: 20px;
    height: 45px;
    padding: 2px;

    .dev-tools-dropmenu-container {
      width: 365px;
      top: -220px;
      left: -15px;
    }
  }
}
