.navigation-search {
  display: flex;

  .so-input {
    background-color: rgba(0, 0, 0, .2);
    border: 2px solid transparent;
    transition: border-color .15s ease-out, background-color .15s ease-out, color .15s ease-out, border-color .15s ease-out, background-color .15s ease-out, color .15s ease-out;
    color: #fff;

    &:focus {
        background-color: rgba(0, 0, 0, .3);
        border-color: transparent;
        outline: 0;
    }
  }

  .search-icon-container {
    background-color: #284d66;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
