.login-page {
  * {
    font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
  }

  width: 100%;
  height: 100vh;
  background-color: cadetblue;
  padding-top: 280px;

  background-image: linear-gradient(to right, #2a516a 0, #0d5e57 88%);
  padding-top: 230px;
  box-sizing: border-box;

  .login-logo-container {
    width: 110px;
  }

  .login-form {
    width: 270px;
    width: 370px;
    margin: auto;
    background-color: cadetblue;
    padding: 50px;

    input {
      width: 100%;
      border: none;
      border-radius: 3px;
      color: white;
      background-color: rgba(0, 0, 0, 0.2);
      height: 40px;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;

      &::placeholder {
        color: white;
        font-style: italic;
      }

      &:disabled {
        background-color: rgba(0, 0, 0, 0.5);
        color: darkgray;
      }
    }

    button {
      width: 100%;
      background-color: white;
      border: none;
      height: 40px;
      margin-top: 20px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 18px;
      transition: all 0.3s;

      &:disabled {
        opacity: 0.3;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
      }
    }

    .email-error {
      color: white;
      padding: 5px;
      margin-top: 15px;

      text-align: center;
    }

    .login-loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
    }
  }

  .login-action-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    a {
      color: white;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
