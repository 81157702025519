.pagination-container {
  display: flex;
  margin: 5px;

  .pagination-element {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    line-height: 30px;
    border: 1px solid gainsboro;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: gainsboro;
    }

    &.active {
      color: white;
      background-color: #1abcae;
    }

    .pagination-arrow-left {
      margin-top: 3px;
      svg {
        transform: rotate(180deg);
      }
    }
    .pagination-arrow-right {
      margin-top: 3px;
    }
  }
}
