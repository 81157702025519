.social-channels-bargraphs-container {
  display: flex;
}

.social-channel-title {
  font-family: "Source Sans Pro Webfont";
  font-size: 20px;
  font-weight: 300;
  margin: 0px;
  margin-top: 7px;
  text-transform: capitalize;
  color: #4a4a4a;
}

.social-channel-performance {
  font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
  font-size: 30px;
  color: #4a4a4a;
  margin: 0px;
  position: relative;
  top: -5px;
}
