@import "../../assets/stylesheets/variables.scss";

.story-reach-container {
  margin-bottom: 30px;
  h3 {
    font-family: "Roboto Slab Webfont", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25;
    letter-spacing: initial;
    text-transform: none;
  }

  h3,
  h4 {
    color: #4a4a4a;
    margin-top: 0px;
  }

  .graphs-container {
    display: flex;

    .page-views-graph {
      background-color: white;
      width: 230px;
      margin-right: 15px;
      padding: 20px 10px;
      position: relative;

      h4 {
        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        margin: 0px;
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
      }

      .performance {
        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin: 0px;
      }

      .circle-graph-container {
        position: relative;
        top: 40px;

        .performance-index {
          font-family: "Roboto Slab Webfont";
          font-size: 60px;
          position: absolute;
          top: -20px;
          font-weight: 300;
          margin: 0px;
        }
      }

      .pageviews-expectations {
        padding-top: 100px;

        .expectations-label {
          font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
          font-size: 20px;
          font-weight: 300;
          margin: 0px;
        }

        .expectations-value {
          font-family: "Roboto Slab Webfont";
          font-size: 30px;
          margin: 0px;
        }

        .expectations-not-available {
          font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
          font-size: 16px;
          font-weight: 300;
          margin: 0px;
        }
      }
    }

    .origins-graph {
      background-color: white;
      width: 800px;
      margin-right: 15px;
      padding: 20px 10px;
      position: relative;

      .loader-container {
        position: relative;
        top: -30px;
      }

      .segment-select-container {
        z-index: 998;
        position: absolute;
        width: 250px;
        right: 10px;
        top: 10px;
        font-size: 14px;
        font-family: "Source Sans Pro Webfont";
        font-weight: 300;

        .segments-dropdown-container {
          background-color: white;
        }
      }

      h4 {
        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .details-graph {
      background-color: white;
      min-width: 200px;
      padding: 20px 15px;

      h4 {
        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
      }

      .performance {
        font-family: "Source Sans Pro Webfont", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin: 0px;
        margin-bottom: 25px;
      }
    }
  }

  .loader-container {
    width: 100%;
    height: 300px;
    text-align: center;
  }
}

@media screen and (max-width: $mobile-navigation-responsive-break-point) {
  .story-reach-container {
    .graphs-container {
      display: flex;
      flex-direction: column;

      .page-views-graph {
        margin-bottom: 15px;
      }

      .origins-graph {
        width: 100%;
        margin-bottom: 15px;
        overflow: auto;

        .segment-select-container {
          z-index: 2;
        }
      }

      .details-graph {
        margin-bottom: 15px;
        min-width: 50px;
      }
    }
  }
}
