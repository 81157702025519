.report-row-growth-indicators-container {
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-bottom: 5px solid #d3d5da;
    margin: 1px;

    &.green {
      border-bottom: 5px solid #60d470;
    }
  }
}
