.imports-modal-wrapper {
  background-color: rgb(255, 255, 255);
  width: 800px;
  padding: 20px;
  color: #000000;
  margin-top: 60px;

  .imports-modal-title {
    font-family: "Roboto Slab Webfont", Halvetica;
    font-size: 26px;
    margin: 0px 0px 10px 5px;
    font-weight: 400;
  }

  .imports-modal-container {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .column {
      width: 48%;
      &.narrow {
        width: 31%;
      }
    }

    .textarea-code-editor {
      height: 200px;
      resize: none;
      border-color: lightgrey;
      position: relative;
      top: -5px;
    }

    .drop-select {
      margin-bottom: 20px;
      label {
        display: block;
        margin-bottom: 10px;
      }
      .options-dropdown {
        z-index: 999999;
        max-height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        border: 1px solid gray;
      }
    }

    .switch-container {
      margin-bottom: 20px;
    }
  }

  .params-container {
    height: 65vh;
    overflow: auto;
    overflow-x: auto;
    padding-right: 10px;

    label {
      display: block;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    textarea {
      resize: none;
      height: 150px;
    }
  }
}
